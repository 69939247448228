import Image from 'next/image';
import { Stack, Typography, Chip } from '@mui/material';
import { clampLinesSx } from '@qb/frontend/utils/clampLinesSx';
import { formatCents, getDiscountPercentage } from '@qb/frontend/utils/formatCurrency';
import { getFirstImage } from '@qb/frontend/utils/getFirstImage';
import { PartRelevantPartsResponse } from '@/shared/types/controllers/PartControllerTypes';
import { QuickQuote } from '@/src/components/Common/QuickQuote';
import { StockFound } from '@/src/components/Common/StockFound';
type PartCardHorizontalInnerProps = {
  part: PartRelevantPartsResponse['relevantParts'][0];
};
export const PartCardHorizontalInner = ({
  part
}: PartCardHorizontalInnerProps) => {
  const discountPercentage = getDiscountPercentage(part.originalPrice, part.price);
  const isDiscounted = !!discountPercentage;
  return <Stack direction="row" alignItems="center" spacing={2} sx={{
    py: 1,
    px: 1.5,
    minWidth: 200,
    width: '100%'
  }} data-sentry-element="Stack" data-sentry-component="PartCardHorizontalInner" data-sentry-source-file="PartCardHorizontalInner.tsx">
      <Image src={getFirstImage(part.images)} width={50} height={50} alt={part.partNumber} style={{
      objectFit: 'contain'
    }} data-sentry-element="Image" data-sentry-source-file="PartCardHorizontalInner.tsx" />
      <Stack direction="column" sx={{
      width: '100%',
      overflow: 'hidden'
    }} data-sentry-element="Stack" data-sentry-source-file="PartCardHorizontalInner.tsx">
        <Stack direction="row" spacing={1} justifyContent="space-between" mb={1} data-sentry-element="Stack" data-sentry-source-file="PartCardHorizontalInner.tsx">
          <Typography variant="h6" sx={{
          mr: 'auto'
        }} data-sentry-element="Typography" data-sentry-source-file="PartCardHorizontalInner.tsx">
            {part.partNumber}
          </Typography>
          <Stack direction="row" gap={1} data-sentry-element="Stack" data-sentry-source-file="PartCardHorizontalInner.tsx">
            {isDiscounted && <Chip color="accent" size="small" sx={{
            height: 19
          }} label={`${discountPercentage}% off`} />}
            {!!part.price && <Typography variant="h6" color="primary">
                {formatCents(part.price)}
                {isDiscounted && !!part.originalPrice && <>
                    <br />
                    <Typography variant="bodySmall" fontWeight={600} sx={{
                color: 'text.secondary',
                textDecoration: 'line-through'
              }}>
                      {formatCents(part.originalPrice)}
                    </Typography>
                  </>}
              </Typography>}
          </Stack>
        </Stack>
        <Typography title={part.description} variant="bodyMedium" mb={1} sx={clampLinesSx(1)} data-sentry-element="Typography" data-sentry-source-file="PartCardHorizontalInner.tsx">
          {part.description}
        </Typography>
        <Stack mt="auto" direction="row" justifyContent="space-between" data-sentry-element="Stack" data-sentry-source-file="PartCardHorizontalInner.tsx">
          <Typography variant="bodyMediumPrimary" color="primary.light" noWrap data-sentry-element="Typography" data-sentry-source-file="PartCardHorizontalInner.tsx">
            {part.manufacturer.name}
          </Typography>
          {part.onHandQty ? <StockFound stock={part.onHandQty} showFastShipping={false} /> : <QuickQuote />}
        </Stack>
      </Stack>
    </Stack>;
};