import { DateTimeFormat } from '@qb/frontend/utils/dateFormatter';
import { AppDisplayDataResponse } from '@/shared/types/controllers/AppControllerTypes';
import { AvailableApp } from '@/shared/types/controllers/WidgetControllerTypes';

type Days =
  | 'Sunday'
  | 'Monday'
  | 'Tuesday'
  | 'Wednesday'
  | 'Thursday'
  | 'Friday'
  | 'Saturday';

export const getIsTeamOnline = (
  app: AppDisplayDataResponse['app'] | AvailableApp,
): boolean => {
  /**
   * Convert current time to the app's timezone, then we can get the current day and time and compare to the settings.
   * @example
   * ```
   * [
   *   { type: 'weekday', value: 'Tuesday' },
   *   { type: 'literal', value: ' ' },
   *   { type: 'hour', value: '02' },
   *   { type: 'literal', value: ':' },
   *   { type: 'minute', value: '25' },
   * ]
   * ```
   */
  const parts = DateTimeFormat('en-US', {
    weekday: 'long',
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
    timeZone: app.workingHoursTimezone,
  }).formatToParts(new Date());

  const weekday = parts.find(({ type }) => type === 'weekday')?.value as Days;
  const hours = parts.find(({ type }) => type === 'hour')?.value as string;
  const minutes = parts.find(({ type }) => type === 'minute')?.value as string;

  const minutesFromStartOfDay = Number(hours) * 60 + Number(minutes);

  const isWorkingHours =
    minutesFromStartOfDay >= app.workingHoursStart &&
    minutesFromStartOfDay < app.workingHoursEnd;

  if (!isWorkingHours) return false;

  return app[`working${weekday}`];
};
