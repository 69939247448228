'use client';

import { memo } from 'react';
import { usePathname } from 'next/navigation';
import { Box } from '@mui/material';
import { AppDisplayDataResponse } from '@/shared/types/controllers/AppControllerTypes';
import { NavbarAgents } from './components/NavbarAgents';
import { NavbarAnnouncementBanner } from './components/NavbarAnnouncementBanner';
import { NavbarLiveSupportBanner } from './components/NavbarLiveSupportBanner';
import { StickyNavbar } from './components/StickyNavbar';
type Props = {
  appDetails?: AppDisplayDataResponse['app'];
};
const NavbarInternal = ({
  appDetails
}: Props) => {
  const pathname = usePathname() || '';
  const isLiveSupportBannerVisible = ['/'].includes(pathname);
  const isAnnouncementBannerVisible = false;
  const inlineAgents = isLiveSupportBannerVisible ? null : <Box sx={{
    p: 0.5,
    bgcolor: 'common.white',
    display: {
      xs: 'none',
      lg: 'block'
    }
  }}>
      <NavbarAgents appDetails={appDetails} />
    </Box>;
  return <>
      {isAnnouncementBannerVisible && <NavbarAnnouncementBanner />}
      {isLiveSupportBannerVisible && <NavbarLiveSupportBanner appDetails={appDetails} />}
      <StickyNavbar hasBackground={!isLiveSupportBannerVisible} data-sentry-element="StickyNavbar" data-sentry-source-file="Navbar.tsx">
        {inlineAgents}
      </StickyNavbar>
    </>;
};
export const Navbar = memo(NavbarInternal);