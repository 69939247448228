import { memoize } from 'lodash';

/**
 * Resolving Intl.DateTimeFormat options and creating a new instance is an slow operation, it's best to memoize it.
 * @see Usage Cheatsheet: https://devhints.io/wip/intl-datetime
 */
export const DateTimeFormat = memoize(
  Intl.DateTimeFormat,
  (...args: unknown[]) => JSON.stringify(args),
);

/**
 * @date date - date string or Date object
 * @param timeZone - if undefined, it will auto convert to the local timezone. use `UTC` for UTC time.
 */
type FormatFunction = (
  date: string | Date,
  timeZone?: 'UTC' | string,
) => string;

/**
 * @example `2:30 PM`
 */
export const formatTime: FormatFunction = (time, timeZone?) => {
  if (!time) return '';
  return DateTimeFormat('en-US', {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
    timeZone,
  }).format(new Date(time));
};

/**
 * Outputs a date format with a lexical month name.
 * @example `Mar 4, 2024`, `Jan 15, 2024`
 */
export const formatDate: FormatFunction = (date, timeZone?) => {
  if (!date) return '';
  return DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    timeZone,
  }).format(new Date(date));
};

/**
 * Outputs a US standard date format.
 * @example `3/4/2024`, `1/15/2024`
 */
export const formatDateNumerical: FormatFunction = (date, timeZone?) => {
  if (!date) return '';
  return DateTimeFormat('en-US', { timeZone }).format(new Date(date));
};

/**
 * @example `2024-03-05`
 */
export const formatDateDashes: FormatFunction = (date, timeZone?) => {
  if (!date) return '';
  const standardDateStr = DateTimeFormat('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    timeZone,
  }).format(new Date(date));

  const [month, day, year] = standardDateStr.split('/');

  return `${year}-${month}-${day}`;
};

/**
 * date time format with short month name.
 * @example `Mar 4, 2024, 2:30 PM`
 */
export const formatDateTime: FormatFunction = (dateTime, timeZone?) => {
  if (!dateTime) return '';
  return DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
    timeZone,
  }).format(new Date(dateTime));
};

/**
 * @example `Dec 11, 2023, 1:07 PM GMT+1`
 */
export const formatDateTimeAndZone: FormatFunction = (dateTime, timeZone?) => {
  if (!dateTime) return '';
  return DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    timeZoneName: 'short',
    hour12: true,
    timeZone,
  }).format(new Date(dateTime));
};

/**
 * @example `Mon, Dec 11, 2023, 1:07 PM GMT+1`
 */
export const formatDayDateTimeAndZone: FormatFunction = (
  dateTime,
  timeZone?,
) => {
  if (!dateTime) return '';
  return DateTimeFormat('en-US', {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    timeZoneName: 'short',
    hour12: true,
    timeZone,
  }).format(new Date(dateTime));
};
