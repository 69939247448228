import currency from 'currency.js';
import { isNumber } from '@/shared/types/util/TypeGuards';

export const formatCents = (value: currency.Any) =>
  currency(value, {
    fromCents: true,
  }).format();

export const getCentsValue = (value: currency.Any) =>
  currency(value, {
    fromCents: true,
  }).value;

export const getCentsIntValue = (value: currency.Any) =>
  currency(value, {
    fromCents: true,
  }).intValue;

export const formatDollars = (value: currency.Any) => currency(value).format();

export const formatCentsNoSymbolNoSeparator = (value: currency.Any) =>
  currency(value, { fromCents: true, symbol: '', separator: '' }).format();

export const convertCentsToCurrency = (cents: number) =>
  currency(cents, { fromCents: true });

export const getDiscountPercentage = (
  originalPrice: number | undefined,
  price: number | undefined,
): number => {
  if (!isNumber(originalPrice) || !isNumber(price)) return 0;

  const diff = originalPrice - price;
  return Math.round((diff / originalPrice) * 100);
};
