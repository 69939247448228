import type { ElementType } from 'react';
import { match } from 'css-mediaquery';
import { PaletteColor, rgbToHex, lighten } from '@mui/material';
import {
  CssVarsThemeOptions,
  ThemeOptions,
  Theme,
  shouldSkipGeneratingVar as defaultShouldSkipGeneratingVar,
} from '@mui/material/styles';
import { deepmerge } from '@mui/utils';

export const mergeCssVarsThemeOptions = (
  ...objects: CssVarsThemeOptions[]
): CssVarsThemeOptions =>
  objects.reduce(
    (accumulatedOptions, currentOptions) =>
      deepmerge(accumulatedOptions, currentOptions),
    {},
  );

export const mergeThemeOptions = (...objects: ThemeOptions[]): ThemeOptions =>
  objects.reduce(
    (accumulatedOptions, currentOptions) =>
      deepmerge(accumulatedOptions, currentOptions),
    {},
  );

export const mergeTheme = (...objects: Theme[]): Theme =>
  objects.reduce(
    (accumulatedOptions, currentOptions) =>
      deepmerge(accumulatedOptions, currentOptions),
    {} as Theme,
  );

export const generateColorLightShades = (
  color: string,
): Partial<PaletteColor> => ({
  '50p': rgbToHex(lighten(color, 1 - 0.5)),
  '30p': rgbToHex(lighten(color, 1 - 0.3)),
  '12p': rgbToHex(lighten(color, 1 - 0.12)),
  '8p': rgbToHex(lighten(color, 1 - 0.08)),
  '4p': rgbToHex(lighten(color, 1 - 0.04)),
});

export const integrateThemeWithRouter = (LinkComponent: ElementType) => ({
  components: {
    MuiLink: {
      defaultProps: {
        component: LinkComponent,
      },
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkComponent,
      },
    },
  },
});

export const addTransitionShorthand: CssVarsThemeOptions = {
  unstable_sxConfig: {
    /**
     * In order to access the theme transition values without the needs to access the theme object via a function or a hook
     * which would prevent us from making some components a server-component
     * This is not meant to be used as a replacement for `theme.transitions.create` function but to complement it.
     * @see https://mui.com/system/getting-started/usage/#shorthands
     * @see https://mui.com/system/experimental-api/configure-the-sx-prop/#extend-the-sx-prop
     */
    transitionDuration: {
      themeKey: 'transitions.duration',
      transform: (value) => `${value}ms`,
    },
    transitionTimingFunction: {
      themeKey: 'transitions.easing',
    },
  },
};

export const addSharpCornersStyle: CssVarsThemeOptions = {
  shape: {
    // Removing the `borderRadius` from `shape` is preventing us from overriding in the `sx` props.
    // borderRadius: 0,
  },
  components: {
    MuiInputBase: { styleOverrides: { root: { borderRadius: 0 } } },
    MuiOutlinedInput: { styleOverrides: { root: { borderRadius: 0 } } },
    MuiPaginationItem: { styleOverrides: { root: { borderRadius: 0 } } },
    MuiPaper: {
      defaultProps: { square: true },
      styleOverrides: { root: { borderRadius: 0 } },
    },
    MuiButtonBase: { styleOverrides: { root: { borderRadius: 0 } } },
    MuiButton: {
      styleOverrides: {
        sizeSmall: { borderRadius: 0 },
        root: { borderRadius: 0 },
        sizeLarge: { borderRadius: 0 },
      },
    },
    MuiAlert: { styleOverrides: { root: { borderRadius: 0 } } },
  },
};

export const webkitSearchInputReset = {
  '::-webkit-search-decoration': { WebkitAppearance: 'none' },
  '::-webkit-search-cancel-button': { WebkitAppearance: 'none' },
  '::-webkit-search-results-button': { WebkitAppearance: 'none' },
  '::-webkit-search-results-decoration': { WebkitAppearance: 'none' },
};

/**
 * Prevents webkit browser from applying styles to search input
 */
export const resetSearchInputStyle = (): CssVarsThemeOptions => {
  return {
    components: {
      MuiFilledInput: {
        styleOverrides: { input: { ...webkitSearchInputReset } },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          input: {
            '&::placeholder': { opacity: 1 },
            '&:focus::placeholder': { color: 'transparent' },
            ...webkitSearchInputReset,
          },
        },
      },
    },
  };
};

export const changeTheDefaultProps: CssVarsThemeOptions = {
  components: {
    MuiDialogContentText: { defaultProps: { variant: 'bodyMedium' } },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
        disableElevation: true,
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
        variant: 'outlined',
      },
    },
    MuiLink: { defaultProps: { underline: 'hover' } },
    MuiAlert: {
      defaultProps: { severity: 'error' },
      styleOverrides: {
        root: {
          width: '100%',
          marginBottom: 16,
        },
      },
    },
  },
};

export const changeDefaultButtonsHeight: CssVarsThemeOptions = {
  components: {
    MuiButton: {
      styleOverrides: {
        sizeSmall: { height: 30 },
        sizeMedium: { height: 40 },
        sizeLarge: { height: 50 },
      },
    },
  },
};

export const changeTheFontFamily = (
  fontFamily: string,
): CssVarsThemeOptions => ({
  typography: {
    fontFamily,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontFamily,
        },
      },
    },
  },
});

export const addXXLBreakpoint = (): CssVarsThemeOptions => ({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      xxl: 2000,
    },
  },
});

/**
 * Experimental way to generate CSS variables for breakpoints, which allows us to use them in the theme options.
 */
export const generateBreakpointsCssVars = (): CssVarsThemeOptions => ({
  shouldSkipGeneratingVar: (
    keys: string[],
    _value: string | number,
  ): boolean => {
    if (keys.includes('breakpoints') && keys.includes('values')) {
      return false;
    }
    return defaultShouldSkipGeneratingVar(keys);
  },
});

/**
 * Experimental solution to provide correct mediaQueries based on device type extracted from user-agent.
 * This is useful to prevent flashes and big differences between the SSR and Client Side versions
 * @see https://dev.to/rakshitnayak/how-to-implement-server-side-rendering-for-material-uis-media-queries-in-nextjs-to-avoid-flash-jpi
 * @see https://mui.com/material-ui/react-use-media-query/#server-side-rendering
 */
export const addSSRMatchMedia = (
  isUserAgentDeviceMobile: boolean,
): CssVarsThemeOptions => ({
  components: {
    MuiUseMediaQuery: {
      defaultProps: {
        ssrMatchMedia: (query) => ({
          matches: match(query, {
            // The estimated CSS width of the browser based on the device user-agent
            width: isUserAgentDeviceMobile ? '0px' : '1200px',
          }),
        }),
      },
    },
  },
});
