import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  SocketGetRequest,
  SocketGetRequestWithData,
} from '@qb/httpRequest/SocketRequest';
import { WebAPIPrefix } from '@qb/httpRequest/constants';
import {
  ConversationDisplayDataResponse,
  ConversationListDataResponse,
  ConversationSearchResponse,
} from '@/shared/types/controllers/ConversationControllerTypes';

export const SEARCH_TEXT_MIN_LENGTH = 3;

const fetchConversationData = createAsyncThunk<
  {
    conversation: ConversationDisplayDataResponse['conversation'];
    setAsSelected: boolean;
  },
  { conversationID: number; setAsSelected: boolean }
>(
  `${WebAPIPrefix}/conversation/:id/displayData`,
  async ({ conversationID, setAsSelected }) => {
    return new Promise((resolve, reject) => {
      if (conversationID) {
        SocketGetRequest(
          `${WebAPIPrefix}/conversation/${conversationID}/displayData`,
          (data: ConversationDisplayDataResponse) => {
            return resolve({ ...data, setAsSelected });
          },
          (err: Error) => {
            console.error(err);
            reject(err);
          },
        );
      } else {
        return reject();
      }
    });
  },
);

const fetchConversationListData =
  createAsyncThunk<ConversationListDataResponse>(
    `${WebAPIPrefix}/conversation/listData`,
    async (page) => {
      return new Promise((resolve, reject) => {
        SocketGetRequestWithData(
          `${WebAPIPrefix}/conversation/listData`,
          { page: page },
          resolve,
          reject,
        );
      });
    },
  );

const fetchConversationsBySearch = createAsyncThunk<
  ConversationSearchResponse,
  string
>(`${WebAPIPrefix}/conversation/search`, async (searchTerm: string) => {
  return new Promise((resolve, reject) => {
    if (searchTerm.trim().length < SEARCH_TEXT_MIN_LENGTH) {
      resolve({ searchConversationResults: [] });
      return;
    }
    SocketGetRequestWithData(
      `${WebAPIPrefix}/conversation/search`,
      { searchTerm: searchTerm },
      resolve,
      reject,
    );
  });
});

export {
  fetchConversationData,
  fetchConversationListData,
  fetchConversationsBySearch,
};
