var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"qb-production-release@2024-08-26T20:16:36.330Z"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import { init } from '@sentry/nextjs';
import { getBaseSentryClientConfig } from '@qb/base-config/base-sentry.client.config';

init({
  ...getBaseSentryClientConfig(false),
  dsn: 'https://a8343e3474a641dfae0a9c649be5a56a@o4504018562842624.ingest.us.sentry.io/4504018679562241',
});
