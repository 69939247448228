import { API_HOST } from '../../apps/web/WebConstants';

export const WebAPIPrefixWithoutHost = '/api/web';
export const RestAPIPrefixWithoutHost = '/api/rest';
export const SPSAPIPrefixWithoutHost = '/api/sps';
export const SPAAPIPrefixWithoutHost = '/api/spa';

export const WebAPIPrefix = API_HOST + WebAPIPrefixWithoutHost;
export const RestAPIPrefix = API_HOST + RestAPIPrefixWithoutHost;
export const SPAAPIPrefix = API_HOST + '/api/spa';

// api host is changed based on the tenant config through axiosInstance.defaults.baseURL
export const WidgetAPIPrefix = '/api/widget';
export const InternalAdminPrefix = API_HOST + '/internalAdmin';

export const UNAUTHORIZED_ERROR_CODE = 401;
export const FORBIDDEN_ERROR_CODE = 403;
export const NOT_FOUND_ERROR_CODE = 404;

export const BAD_REQUEST_EVENT = 'BAD_REQUEST_EVENT';

export const SERVER_ERROR_MESSAGE =
  'Oops, something is not working… We have been notified. Please try again soon 🥲';
