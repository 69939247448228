import currency from 'currency.js';
import { LocalStorage } from '@qb/frontend/utils/localStorage';
import { CheckoutCartPart } from '@/shared/types/controllers/CartControllerTypes';
import { PartPopularPartsResponse } from '@/shared/types/controllers/PartControllerTypes';
import { EcommerceItem } from './ga4Types';

export const GA4_LOCAL_STORAGE_KEY = 'ga4_additional_data';

// example Part type
type Part = PartPopularPartsResponse['parts'][0];

export type GetGA4EcommerceListItemParams = {
  part: {
    id: Part['id'];
    partNumber: Part['partNumber'];
    partCategory: { name: Part['partCategory']['name'] };
    manufacturer: { name: Part['manufacturer']['name'] };
    price?: Part['price'];
    onHandQty?: Part['onHandQty'];
  };
  index: EcommerceItem['index'];
};

export const getGA4EcommerceListItem = ({
  part: { id, partNumber, partCategory, manufacturer, price, onHandQty },
  index,
}: GetGA4EcommerceListItemParams): EcommerceItem => {
  const priceValue = price
    ? currency(price, { fromCents: true }).value
    : undefined;

  return {
    index,
    item_id: id,
    item_name: partNumber,
    item_category: partCategory.name,
    item_brand: manufacturer.name,
    currency: 'USD',
    price: priceValue,
    quantity: 1, // we don't have option to choose qty inside lists for now
    items_in_stock: onHandQty,
  };
};

export type GetGA4EcommerceCartItemParams = {
  cartPart: {
    part: {
      id: CheckoutCartPart['part']['id'];
      partNumber: CheckoutCartPart['part']['partNumber'];
      partCategory: { name: CheckoutCartPart['part']['partCategory']['name'] };
      manufacturer: { name: CheckoutCartPart['part']['manufacturer']['name'] };
    };
    price: CheckoutCartPart['price'];
    qty: CheckoutCartPart['qty'];
  };
  itemListNameFromParent?: EcommerceItem['item_list_name'];
};

export const getGA4EcommerceCartItem = ({
  cartPart,
  itemListNameFromParent,
}: GetGA4EcommerceCartItemParams): EcommerceItem => {
  const ga4dataFromLocalStorage = LocalStorage.getItem(GA4_LOCAL_STORAGE_KEY);
  const existingAdditionalData: {
    partID: number;
    item_list_name: EcommerceItem['item_list_name'];
  }[] = JSON.parse(ga4dataFromLocalStorage || '[]');

  const itemListNameFromLocalStorage = existingAdditionalData.find(
    (partData) => partData.partID === cartPart.part.id,
  )?.item_list_name;

  const itemListName = itemListNameFromParent ?? itemListNameFromLocalStorage;

  if (itemListNameFromParent) {
    setGA4AdditionalDataInLocalStorage(
      cartPart.part.id,
      itemListNameFromParent,
    );
  }

  const baseEcommerceItem = getGA4EcommerceListItem({
    part: {
      id: cartPart.part.id,
      partNumber: cartPart.part.partNumber,
      partCategory: { name: cartPart.part.partCategory.name },
      manufacturer: { name: cartPart.part.manufacturer.name },
      price: cartPart.price,
    },
    index: 0,
  });

  return {
    ...baseEcommerceItem,
    quantity: cartPart.qty,
    item_list_name: itemListName,
    item_list_id: itemListName,
  };
};

export const setGA4AdditionalDataInLocalStorage = (
  partID: number,
  itemListNameFromParent: string | undefined,
) => {
  const dataFromLocalStorage = LocalStorage.getItem(GA4_LOCAL_STORAGE_KEY);
  const existingAdditionalData: { partID: number; item_list_name: string }[] =
    JSON.parse(dataFromLocalStorage || '[]');

  // remove existing data for this part
  const filteredExistingAdditionalData = existingAdditionalData.filter(
    (partData) => partData.partID !== partID,
  );

  LocalStorage.setItem(
    GA4_LOCAL_STORAGE_KEY,
    JSON.stringify([
      ...filteredExistingAdditionalData,
      { partID: partID, item_list_name: itemListNameFromParent },
    ]),
  );
};
