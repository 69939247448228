export type EnvName = 'development' | 'staging' | 'production';

export type TenantAlias =
  | 'advantageelectricsupply'
  | 'appliedautomation'
  | 'demo'
  | 'lakewood'
  | 'mechatronicsolutions'
  | 'powermatic'
  | 'quotebeam';

type TenantAliasConfig = {
  tenantAlias: TenantAlias;
  appID: number;
  tenantID: number;
  isDemo: boolean;
  sitesHosts: string[];
  agentHosts: string[];
  apiHost?: string;
};

type TenantAliasConfigList = Record<
  TenantAlias,
  Record<EnvName, TenantAliasConfig>
>;

const getTenantAliasConfigOverride = () => {
  try {
    return JSON.parse(
      process.env.TENANT_ALIAS_MAP_OVERRIDE || '{}',
    ) as TenantAliasConfigList;
  } catch (e) {
    console.warn('Invalid process.env.TENANT_ALIAS_MAP_OVERRIDE detected', e);
    return {};
  }
};

export const tenantAliasConfig: TenantAliasConfigList = {
  advantageelectricsupply: {
    development: {
      tenantAlias: 'advantageelectricsupply',
      appID: 3,
      tenantID: 18,
      isDemo: false,
      sitesHosts: ['advantageelectricsupply.localhost.com'],
      agentHosts: ['agentadvantageelectricsupply.localhost.com'],
    },
    staging: {
      tenantAlias: 'advantageelectricsupply',
      appID: 3,
      tenantID: 18,
      isDemo: false,
      sitesHosts: [
        'advantageelectricsupply.salespilotsites-staging.com',
        'advantageelectricsupplyproxy.salespilotsites-staging.com',
      ],
      agentHosts: ['agent.advantageelectricsupply.salespilotsites-staging.com'],
    },
    production: {
      tenantAlias: 'advantageelectricsupply',
      appID: 3,
      tenantID: 18,
      isDemo: false,
      sitesHosts: [
        'advantageelectricsupply.salespilotsites.com',
        'advantageelectricsupply.com',
        'www.advantageelectricsupply.com',
      ],
      agentHosts: [
        'agent.advantageelectricsupply.com',
        'agent.advantageelectricsupply.salespilotsites.com',
      ],
      apiHost: 'api.advantageelectricsupply.com',
    },
  },
  appliedautomation: {
    development: {
      tenantAlias: 'appliedautomation',
      appID: 3,
      tenantID: 18,
      isDemo: false,
      sitesHosts: ['appliedautomation.localhost.com'],
      agentHosts: ['agentappliedautomation.localhost.com'],
    },
    staging: {
      tenantAlias: 'appliedautomation',
      appID: 47,
      tenantID: 2769,
      isDemo: false,
      sitesHosts: ['appliedautomation.salespilotsites-staging.com'],
      agentHosts: ['agent.appliedautomation.salespilotsites-staging.com'],
    },
    production: {
      tenantAlias: 'appliedautomation',
      appID: 80,
      tenantID: 4925,
      isDemo: false,
      sitesHosts: [
        'appliedautomation.salespilotsites.com',
        'appliedautomation.com',
      ],
      agentHosts: [
        'agent.appliedautomation.com',
        'agent.appliedautomation.salespilotsites.com',
      ],
    },
  },
  demo: {
    development: {
      tenantAlias: 'demo',
      appID: 2,
      tenantID: 185,
      isDemo: true,
      sitesHosts: ['demo.localhost.com'],
      agentHosts: ['agentdemo.localhost.com'],
    },
    staging: {
      tenantAlias: 'demo',
      appID: 1,
      tenantID: 10,
      isDemo: true,
      sitesHosts: ['demo.salespilotsites-staging.com', 'smartengineerblog.com'],
      agentHosts: ['agent.demo.salespilotsites-staging.com'],
    },
    production: {
      tenantAlias: 'demo',
      appID: 1,
      tenantID: 11,
      isDemo: true,
      sitesHosts: ['demo.salespilotsites.com'],
      agentHosts: ['agent.demo.salespilotsites.com'],
    },
  },
  lakewood: {
    development: {
      tenantAlias: 'lakewood',
      appID: 2,
      tenantID: 185,
      isDemo: false,
      sitesHosts: ['lakewood.localhost.com'],
      agentHosts: ['agentlakewood.localhost.com'],
    },
    staging: {
      tenantAlias: 'lakewood',
      appID: 3,
      tenantID: 12,
      isDemo: true,
      sitesHosts: ['lakewood.salespilotsites-staging.com'],
      agentHosts: ['agent.lakewood.salespilotsites-staging.com'],
    },
    production: {
      tenantAlias: 'lakewood',
      appID: 2,
      tenantID: 14,
      isDemo: true,
      sitesHosts: ['lakewood.salespilotsites.com'],
      agentHosts: ['agent.lakewood.salespilotsites.com'],
    },
  },
  mechatronicsolutions: {
    development: {
      tenantAlias: 'mechatronicsolutions',
      appID: 2,
      tenantID: 185,
      isDemo: true,
      sitesHosts: ['mechatronicsolutions.localhost.com'],
      agentHosts: ['agentmechatronicsolutions.localhost.com'],
    },
    staging: {
      tenantAlias: 'mechatronicsolutions',
      appID: 5,
      tenantID: 21,
      isDemo: true,
      sitesHosts: ['mechatronicsolutions.salespilotsites-staging.com'],
      agentHosts: ['agent.mechatronicsolutions.salespilotsites-staging.com'],
    },
    production: {
      tenantAlias: 'mechatronicsolutions',
      appID: 5,
      tenantID: 16,
      isDemo: true,
      sitesHosts: ['mechatronicsolutions.salespilotsites.com'],
      agentHosts: ['agent.mechatronicsolutions.salespilotsites.com'],
    },
  },
  powermatic: {
    development: {
      tenantAlias: 'powermatic',
      appID: 2,
      tenantID: 185,
      isDemo: false,
      sitesHosts: ['powermatic.localhost.com'],
      agentHosts: ['agentpowermatic.localhost.com'],
    },
    staging: {
      tenantAlias: 'powermatic',
      appID: 2,
      tenantID: 185,
      isDemo: false,
      sitesHosts: ['powermatic.salespilotsites-staging.com'],
      agentHosts: ['agent.powermatic.salespilotsites-staging.com'],
    },
    production: {
      tenantAlias: 'powermatic',
      appID: 2,
      tenantID: 185,
      isDemo: false,
      sitesHosts: [
        'powermatic.salespilotsites.com',
        'powermatic.net',
        'www.powermatic.net',
      ],
      agentHosts: [
        'agent.powermatic.salespilotsites.com',
        'agent.powermatic.net',
      ],
    },
  },
  quotebeam: {
    development: {
      tenantAlias: 'quotebeam',
      appID: 1,
      tenantID: 1,
      isDemo: false,
      sitesHosts: [],
      agentHosts: [],
    },
    staging: {
      tenantAlias: 'quotebeam',
      appID: 1,
      tenantID: 1,
      isDemo: false,
      sitesHosts: [],
      agentHosts: [],
    },
    production: {
      tenantAlias: 'quotebeam',
      appID: 1,
      tenantID: 1,
      isDemo: false,
      sitesHosts: ['quotebeam.com'],
      agentHosts: [],
    },
  },
  ...getTenantAliasConfigOverride(),
};
const getHostName = (host: string | null) => {
  /**
   * Remove the port in case of localhost
   * @example `domain-a`, `powermatic.net`, `domain-a.localhost`
   */
  return (host || '').replace(/:\d+/g, '');
};

export const getCurrentEnvMap = (): Record<TenantAlias, TenantAliasConfig> => {
  const envName = (process.env.ENV_NAME || 'development') as EnvName;

  return Object.entries(tenantAliasConfig).reduce(
    (previousValue, [tenantAlias, allEnvConfig]) => {
      if (allEnvConfig[envName]) {
        return {
          ...previousValue,
          [tenantAlias]: allEnvConfig[envName],
        };
      }

      return previousValue;
    },
    {} as Record<TenantAlias, TenantAliasConfig>,
  );
};

export const parseHostToTenantAlias = (
  host: string | null,
  hostsKey: 'sitesHosts' | 'agentHosts',
): TenantAlias | undefined => {
  const hostname = getHostName(host);
  const currentEnvMap = getCurrentEnvMap();

  return Object.entries(currentEnvMap)
    .filter(([_tenantAlias, config]) => config[hostsKey].includes(hostname))
    .map(([tenantAlias]) => tenantAlias as TenantAlias)
    .at(0);
};

export const parseHostToTenantID = (
  host: string | null,
  hostsKey: 'sitesHosts' | 'agentHosts',
): number | undefined => {
  const hostname = getHostName(host);
  const currentEnvMap = getCurrentEnvMap();

  return Object.entries(currentEnvMap)
    .filter(([_tenantAlias, config]) => config[hostsKey].includes(hostname))
    .map(([, config]) => config.tenantID)
    .at(0);
};

export const getConfigFromTenantAlias = (
  tenantAlias: TenantAlias,
): TenantAliasConfig => {
  const currentEnvMap = getCurrentEnvMap();

  return currentEnvMap[tenantAlias];
};

/**
 * Should be used by backend only because tenantID is not specific enough unless paired with the forDemo flag
 */
export const getConfigFromTenantID = (
  tenantID: number,
  forDemo: boolean,
): TenantAliasConfig => {
  const currentEnvMap = getCurrentEnvMap();

  return Object.values(currentEnvMap).find(
    (config) =>
      config.tenantID === Number(tenantID) && config.isDemo === forDemo,
  ) as TenantAliasConfig;
};
